/* eslint-disable  */
import axios from 'axios'

export function getCataloghi() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, [{}],
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'getCataloghi',
            ACL: '',
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function setCataloghi(cataloghi) {
    // console.log('dentro logiiinnn')
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL_API_SERVER, cataloghi,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'setCatalogo',
            ACL: '',
          },
        }).then(res => resolve(res))
        .catch(error => reject(error))
    })
}
export function deleteCataloghi(recordToDelete) {
    // console.log('dentro logiiinnn')
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(recordToDelete),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'deleteCataloghi',
            ACL: '',
          },
        }).then(res => resolve(res))
        .catch(error => reject(error))
    })
  }
  export function deleteCategorie(recordToDelete) {
      // console.log('dentro logiiinnn')
      return new Promise((resolve, reject) => {
          axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(recordToDelete),
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
              classe: 'Cat-Categoria',
              metodo: 'deleteCategorie',
              ACL: '',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    }
