<template>
  <b-modal
    id="modal-catalogo-event-handler"
    ref="modal-catalogo-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    :title="titleEventHandler"
    no-close-on-backdrop
    @ok.prevent="handleOk"
  >
    <!-- {{ catalogo }} -->
    <validation-observer ref="validation-observer-catalogo-modal-event-handler">
      <b-row no-gutters>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="nome catalogo"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="catalogo.nome"
                  placeholder="Nome"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="codice"
            rules="required"
          >
            <b-form-group
              label="Codice"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="catalogo.codice"
                  placeholder="codice"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="descrizione"
            rules="required"
          >
            <b-form-group
              label="Descrizione"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="catalogo.descrizione"
                  placeholder="descrizione"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { setCataloghi } from '@/@core/api-service/catalog/catalogs'

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      show: false,
      titleEventHandler: '',
      newlistino: 0,
      catalogo: {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
      },
      required,
      locale: 'it',
    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    handleOk() {
      this.setCatalogo1()
    },
    setCatalogo(catalogo) {
      this.catalogo = { ...catalogo }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    async setCatalogo1() {
      setCataloghi(JSON.stringify([{ ...this.catalogo }])).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
          const cataloghi = [...res.data.cataloghi.map(el => ({
            id: el.id,
            nome: el.nome,
            descrizione: el.descrizione,
            codice: el.codice,
          }))]
          // console.log('listini', cataloghi)
          this.$emit('update-catalogo', cataloghi)
          this.resetCatalogo()
          this.$nextTick(() => {
            this.$refs['modal-catalogo-event-handler'].toggle('#toggle-btn')
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    resetCatalogo() {
      this.catalogo = {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
      }
    },
  },
}
</script>

<style>

</style>
